/* Use to force buttons, image, or text to be full 100% */
.full-width {
    width: 100%;
}

.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}
