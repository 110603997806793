img {
    max-width: 100%;
    max-height: 100%;
}

.default-image {
    box-sizing: border-box;
    background-color: var(--genesys-csdt-brand-navy);
    width: 100%;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.default-image img {
    max-height: 50px; /* Max height for svg icons in tiles */
    width: auto;
    margin: 0 auto;
}
