[class^='ellipsis'] {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
}

.ellipsis-1 {
    -webkit-line-clamp: 1;
}

.ellipsis-2 {
    -webkit-line-clamp: 2;
}

.ellipsis-3 {
    -webkit-line-clamp: 3;
}
