/****************************************************
 * Padding
 ****************************************************/
.padding-left-small {
    padding-left: 1rem;
}

.padding-right-small {
    padding-right: 1rem;
}
/****************************************************
 * End Padding
 ****************************************************/

/****************************************************
 * Margins
 ****************************************************/
.margin-left-large {
    margin-left: 2.5rem;
}

.margin-right-large {
    margin-right: 2rem;
}

.margin-top-large {
    margin-top: 2.5rem;
}

.margin-bottom-larger {
    margin-bottom: 4.5rem;
}

.margin-bottom-large {
    margin-bottom: 2.5rem;
}

.margin-right-small {
    margin-right: 1rem;
}

.margin-left-small {
    margin-left: 1rem;
}

.margin-left-x-small {
    margin-left: 1.8rem;
}

.margin-top-small {
    margin-top: 1rem;
}

.margin-bottom-small {
    margin-bottom: 1rem;
}

.margin-top-smaller {
    margin-top: 12px;
}

.margin-top-x-small {
    margin-top: 8px;
}

.margin-bottom-smaller {
    margin-bottom: 12px;
}

.margin-bottom-x-small {
    margin-bottom: 8px;
}

.margin-top-medium {
    margin-top: 1.375rem;
}

.margin-bottom-medium {
    margin-bottom: 1.375rem;
}

.margin-left-smaller {
    margin-left: 0.5rem;
}

.margin-right-smaller {
    margin-right: 0.5rem;
}
/****************************************************
 * End Margins
 ****************************************************/
