a,
a:active,
a:visited {
    color: var(--genesys-csdt-brand-navy);
}

/* H3 or headings in the a link should underline */
a > h3:hover,
a:hover {
    cursor: pointer;
    text-decoration: underline;
}

a.primary-link,
a.primary-link:active,
a.primary-link:visited {
    text-decoration: none;
    color: var(--genesys-csdt-brand-navy);
}

a.primary-link:hover {
    text-decoration: underline;
}

a.brand-link,
a.brand-link:active,
a.brand-link:visited {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    color: var(--genesys-csdt-brand-orange);
}

a.brand-link:hover {
    text-decoration: underline;
}

a.secondary-link,
a.secondary-link:active,
a.secondary-link:visited,
span.secondary-link,
button.secondary-link {
    text-decoration: none;
    color: var(--genesys-csdt-link-blue);
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

a.secondary-link:hover,
span.secondary-link:hover,
button.secondary-link:hover {
    text-decoration: underline;
}

/* Secondary Link (n) style. Similar to secondary link but font size is smaller */
a.link-n,
a.link-n:active,
a.link-n:visited,
button.link-n,
span.link-n {
    text-decoration: none;
    color: var(--genesys-csdt-link-blue);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

a.link-n:hover,
button.link-n:hover,
.button-link:hover {
    text-decoration: underline;
}

a.neutral-link,
a.neutral-link:active,
a.neutral-link:visited,
span.neutral-link {
    color: white;
    text-decoration: none;
}

a.neutral-link:hover,
span.neutral-link:hover {
    text-decoration: underline;
}
