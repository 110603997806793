/* GKN Design System Colors */
/* https://www.figma.com/design/FyfwpcwOl68Q6AdEKk82Ma/GKN-Design-System?node-id=0-1&node-type=canvas&t=ThJiP1ARzSkpyEyF-0 */
body {
    /* primary brand */
    --genesys-csdt-brand-navy: #152550;
    --genesys-csdt-navy: #152550;
    --genesys-csdt-brand-arctic: #b1cada;
    --genesys-csdt-brand-azure: #2243a2;

    /* legacy blue */
    --genesys-csdt-link-blue: #2a60c8;
    --genesys-csdt-shadow-blue: #75a8ff;
    --genesys-csdt-darkest-teal: #276679;
    --genesys-csdt-dark-teal: #327f97;
    --genesys-csdt-brand-light-blue: #3b90aa;
    --genesys-csdt-brand-teal: #00ae9e;

    /* primary orange */
    --genesys-csdt-brand-orange: #ff451a;

    /* legacy orange */
    --genesys-csdt-brand-orange-hover: #ffc4b4;
    --genesys-csdt-burnt-orange: #d83d13;
    --genesys-csdt-yellow-60: #ffae00;
    --genesys-csdt-gold: #ff8f14;

    /* brand neutrals */
    --genesys-csdt-brand-nimbus: #f9f8f5;
    --genesys-csdt-brand-nimbus-shade: #f1efea;

    /* legacy gray */
    --genesys-csdt-primary-gray-1: #3c4148;
    --genesys-csdt-primary-gray-2: #4e5054;
    --genesys-csdt-secondary-gray-1: #83878c;
    --genesys-csdt-secondary-gray-2: #77828f;
    --genesys-csdt-secondary-gray-3: #ababab;
    --genesys-csdt-secondary-gray-4: #c4c4c4;
    --genesys-csdt-neutral-gray-1: #e4e4e4;
    --genesys-csdt-neutral-gray-2: #f3f3f3;
}

/*********************************************************
                    Background Colors
**********************************************************/

.bg-white {
    background-color: white;
}

.bg-transparent {
    background-color: transparent;
}

.bg-brand-navy {
    background-color: var(--genesys-csdt-brand-navy);
}

.bg-navy {
    background-color: var(--genesys-csdt-navy);
}

.bg-link-blue {
    background-color: var(--genesys-csdt-link-blue);
}

.bg-shadow-blue {
    background-color: var(--genesys-csdt-shadow-blue);
}

.bg-darkest-teal {
    background-color: var(--genesys-csdt-darkest-teal);
}

.bg-dark-teal {
    background-color: var(--genesys-csdt-dark-teal);
}

.bg-brand-light-blue {
    background-color: var(--genesys-csdt-brand-light-blue);
}

.bg-brand-teal {
    background-color: var(--genesys-csdt-brand-teal);
}

.bg-brand-orange {
    background-color: var(--genesys-csdt-brand-orange);
}

.bg-burnt-orange {
    background-color: var(--genesys-csdt-burnt-orange);
}

.bg-yellow-60 {
    background-color: var(--genesys-csdt-yellow-60);
}

.bg-gold {
    background-color: var(--genesys-csdt-gold);
}

.bg-primary-gray-1 {
    background-color: var(--genesys-csdt-primary-gray-1);
}

.bg-primary-gray-2 {
    background-color: var(--genesys-csdt-primary-gray-2);
}

.bg-secondary-gray-1 {
    background-color: var(--genesys-csdt-secondary-gray-1);
}

.bg-secondary-gray-2 {
    background-color: var(--genesys-csdt-secondary-gray-2);
}

.bg-secondary-gray-3 {
    background-color: var(--genesys-csdt-secondary-gray-3);
}

.bg-secondary-gray-4 {
    background-color: var(--genesys-csdt-secondary-gray-4);
}

.bg-neutral-gray-1 {
    background-color: var(--genesys-csdt-neutral-gray-1);
}

.bg-neutral-gray-2 {
    background-color: var(--genesys-csdt-neutral-gray-2);
}

.border-brand-navy {
    border: 1px solid var(--genesys-csdt-brand-navy);
}

/*********************************************************
                    Borders
**********************************************************/

.border-none {
    border: none;
}

.border-white {
    border: 1px solid white;
}

.border-brand-navy {
    background-color: var(--genesys-csdt-brand-navy);
}

.border-navy {
    border: 1px solid var(--genesys-csdt-navy);
}

.border-link-blue {
    border: 1px solid var(--genesys-csdt-link-blue);
}

.border-shadow-blue {
    border: 1px solid var(--genesys-csdt-shadow-blue);
}

.border-darkest-teal {
    border: 1px solid var(--genesys-csdt-darkest-teal);
}

.border-dark-teal {
    border: 1px solid var(--genesys-csdt-dark-teal);
}

.border-brand-light-blue {
    border: 1px solid var(--genesys-csdt-brand-light-blue);
}

.border-brand-teal {
    border: 1px solid var(--genesys-csdt-brand-teal);
}

.border-brand-orange {
    border: 1px solid var(--genesys-csdt-brand-orange);
}

.border-burnt-orange {
    border: 1px solid var(--genesys-csdt-burnt-orange);
}

.border-yellow-60 {
    border: 1px solid var(--genesys-csdt-yellow-60);
}

.border-gold {
    border: 1px solid var(--genesys-csdt-gold);
}

.border-primary-gray-1 {
    border: 1px solid var(--genesys-csdt-primary-gray-1);
}

.border-primary-gray-2 {
    border: 1px solid var(--genesys-csdt-primary-gray-2);
}

.border-secondary-gray-1 {
    border: 1px solid var(--genesys-csdt-secondary-gray-1);
}

.border-secondary-gray-2 {
    border: 1px solid var(--genesys-csdt-secondary-gray-2);
}

.border-secondary-gray-3 {
    border: 1px solid var(--genesys-csdt-secondary-gray-3);
}

.border-secondary-gray-4 {
    border: 1px solid var(--genesys-csdt-secondary-gray-4);
}

.border-neutral-gray-1 {
    border: 1px solid var(--genesys-csdt-neutral-gray-1);
}

.border-neutral-gray-2 {
    border: 1px solid var(--genesys-csdt-neutral-gray-2);
}

/*********************************************************
                    Font colors
**********************************************************/

.text-white {
    color: white;
}

.text-black {
    color: black;
}

.text-brand-navy {
    color: var(--genesys-csdt-brand-navy);
}

.text-navy {
    color: var(--genesys-csdt-navy);
}

.text-link-blue {
    color: var(--genesys-csdt-link-blue);
}

.text-shadow-blue {
    color: var(--genesys-csdt-shadow-blue);
}

.text-darkest-teal {
    color: var(--genesys-csdt-darkest-teal);
}

.text-dark-teal {
    color: var(--genesys-csdt-dark-teal);
}

.text-brand-light-blue {
    color: var(--genesys-csdt-brand-light-blue);
}

.text-brand-teal {
    color: var(--genesys-csdt-brand-teal);
}

.text-brand-orange {
    color: var(--genesys-csdt-brand-orange);
}

.text-burnt-orange {
    color: var(--genesys-csdt-burnt-orange);
}

.text-yellow-60 {
    color: var(--genesys-csdt-yellow-60);
}

.text-gold {
    color: var(--genesys-csdt-gold);
}

.text-primary-gray-1 {
    color: var(--genesys-csdt-primary-gray-1);
}

.text-primary-gray-2 {
    color: var(--genesys-csdt-primary-gray-2);
}

.text-secondary-gray-1 {
    color: var(--genesys-csdt-secondary-gray-1);
}

.text-secondary-gray-2 {
    color: var(--genesys-csdt-secondary-gray-2);
}

.text-secondary-gray-3 {
    color: var(--genesys-csdt-secondary-gray-3);
}

.text-secondary-gray-4 {
    color: var(--genesys-csdt-secondary-gray-4);
}

.text-neutral-gray-1 {
    color: var(--genesys-csdt-neutral-gray-1);
}

.text-neutral-gray-2 {
    color: var(--genesys-csdt-neutral-gray-2);
}
