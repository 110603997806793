/****************************************************
 * Headings
 ****************************************************/
h1,
.h1 {
    font-weight: 100;
    font-size: 50px;
    line-height: 60px;
    color: var(--genesys-csdt-primary-gray-1);
    margin: 0;
}

h2,
.h2,
.h2-alt {
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    margin: 0;
}

.h2-alt {
    color: var(--genesys-csdt-brand-navy);
}

@media only screen and (max-width: 1020px) {
    .h2-alt {
        font-size: 26px;
        line-height: 30px;
    }
}

h3,
.h3 {
    margin: 0; /* Reset margin to override */
    font-weight: 100;
    font-size: 35px;
    line-height: 41px;
}

@media only screen and (max-width: 1020px) {
    h3,
    .h3 {
        font-size: 24px;
        line-height: 28px;
    }
}

h4,
.h4 {
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
}

.h4-alt {
    color: white;
}
/****************************************************
 * End Headings
 ****************************************************/

/****************************************************
 * Subtitles
 ****************************************************/
.sub-title-1 {
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
}

.sub-title-2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
}

.sub-title-3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
}

@media only screen and (max-width: 1020px) {
    .sub-title-3 {
        padding-top: 12px;
    }
}

/* Use sub-title 3 alt on dark tiles or backgrounds */
.sub-title-3-alt {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    display: flex; /* Do not delete. Used to show and align status circle */
    justify-content: center;
}

.sub-title-4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-left: 8px;
}
/****************************************************
 * End Subtitles
 ****************************************************/

/****************************************************
 * Paragraphs
 ****************************************************/
.p-body,
p.p-body {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--genesys-csdt-primary-gray-2); /* Primary gray 2 */
}

.p-body-alt {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: white;
    margin: 0;
}

.p-body-bold {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--genesys-csdt-primary-gray-1);
}

/* Override any inner html in the paragraph to reset margin to 0 */
.p-body > * {
    margin: 0;
}

.p-body-small {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--genesys-csdt-primary-gray-2);
}

.p-body-small-alt {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: white;
}

.p-body-large {
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    color: var(--genesys-csdt-primary-gray-2);
}

@media only screen and (max-width: 1020px) {
    .p-body-large {
        padding-top: 16px;
    }
}

p.break-paragraph {
    overflow-wrap: break-word;
}
/****************************************************
 * End Paragraphs
 ****************************************************/

/****************************************************
 * Titles
 ****************************************************/
/* Do not delete. Setting min height on the h3 custom heading allows the margin top auto
 or the read more link to be aligned, if the heading wraps to the second line */
.title-min-height {
    min-height: 3rem;
}

.footer-min-height {
    min-height: 3rem;
    align-items: center;
}
/****************************************************
 * End Titles
 ****************************************************/

.tags-date-timestamp-heading {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--genesys-csdt-primary-gray-2);
}
