@import './styles/_index.css';

body {
    font-family: Roboto, Helvetica, sans-serif;
    padding: 0;
    margin: 0;
    /* specify root font size as a fallback
    for other child nodes that do not specify a font size & use relative sizing(eg. em, rem).
    This also provides consistency because it does not rely on browser default font size. */
    font-size: 16px;
}
