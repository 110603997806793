.tile-layout-2 .tile-vertical .tile-image {
    height: 19.36rem;
}

.tile-border-radius {
    border-radius: 15px;
}

/* Education Beyond Tile Custom Styling */
/* Default beyond empty tile */
.tile-small {
    background-size: 2.5rem;
    height: 9.68rem;
}

.tile-large {
    background-size: 5rem !important; /* Override the default tile large styling that is coming from tile vertical component */
    height: 17.44rem !important; /* Override the default tile large styling that is coming from tile vertical component */
}

/* Optional flag if input is set to add a box shadow for images that have white areas or areas
that blend to closely to the white background */
.tile-box-shadow {
    box-shadow: 0 6px 20px rgba(196, 196, 196, 0.5);
    /* Added default box shadow for images or backgrounds that have white areas near borders */
}

/* Tile background for tile images that have images but don't have the correct height, width and default background */
.tile-background-no-color {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 171px;
    width: 275px;
}

/* Randomized tile background styles. See codepen article https: //codepen.io/Sarah_C/pen/GZKOJJ */
.tile-background-color {
    background-color: var(--genesys-csdt-brand-orange);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 171px;
    width: 275px;
}

li:nth-child(odd) .tile-background-color {
    background-color: var(--genesys-csdt-brand-navy);
}

li:nth-child(even) .tile-background-color {
    background-color: var(--genesys-csdt-brand-teal);
}

li:nth-child(3n) .tile-background-color {
    background-color: var(--genesys-csdt-brand-orange);
}

li:nth-child(4n) .tile-background-color {
    background-color: var(--genesys-csdt-yellow-60);
}

li:nth-child(5n) .tile-background-color {
    background-color: var(--genesys-csdt-brand-navy);
}

li:nth-child(6n) .tile-background-color {
    background-color: var(--genesys-csdt-brand-teal);
}

li:nth-child(7n) .tile-background-color {
    background-color: var(--genesys-csdt-brand-orange);
}

li:nth-child(8n) .tile-background-color {
    background-color: var(--genesys-csdt-yellow-60);
}

@media only screen and (max-width: 1020px) {
    .tile-layout-2 .tile-vertical .tile-image {
        width: auto;
        height: 212px;
    }

    .tile-background-no-color {
        width: auto;
        height: 212px;
    }

    .tile-background-color {
        width: 100%;
        height: 212px;
    }

    .multi-column-mobile .tile-vertical .tile-image-link {
        height: 88px;
        display: block;
    }

    .multi-column-mobile.tile-layout-2 .tile-vertical .tile-image {
        width: 100%;
    }
    .multi-column-mobile .margin-top-small {
        margin-top: 0;
    }
    .multi-column-mobile .margin-bottom-smaller {
        margin-bottom: 0;
    }
}
