/* Input used by login input field, search and password form fields */
input[type='password'],
input[type='text'] {
    font-size: 14px;
    background: white;
    height: 32px;
    border: 1px solid var(--genesys-csdt-primary-gray-2);
    border-radius: 0.25rem;
    box-shadow: inset 0 0 3px rgba(34, 37, 41, 0.24);
    width: 100%;
}

::-webkit-input-placeholder {
    /* WebKit browsers */
    padding-left: 0.5rem;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    padding-left: 0.5rem;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    padding-left: 0.5rem;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    padding-left: 0.5rem;
}

/* Input focus and active state */
input[type='password']:active,
input[type='password']:focus-within input[type='password']:focus-visible,
input[type='text']:active,
input[type='text']:focus-within input[type='text']:focus-visible {
    outline: 1px solid var(--genesys-csdt-link-blue);
}

/* Outline invalid input fields */
input.is-invalid,
input[type='password'].is-invalid,
input[type='text'].is-invalid {
    border-color: var(--genesys-csdt-brand-orange);
}
