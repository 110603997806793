button {
    background-color: transparent;
}

/* Default Base button styling shared by primary, secondary, brand buttons.
Structural css like padding, font, etc... except branding colors */
.btn,
a.btn,
input[type='button'],
input[type='submit'] {
    border: 1px solid var(--genesys-csdt-brand-navy);
    transition: all 0.3s;
    text-decoration: none;
    font-size: 20px;
    line-height: normal;
    font-weight: 400;
    white-space: nowrap;
    padding: 6px 8px;
    display: inline-block;
    cursor: pointer;
    height: 48px;
    border-radius: 2px;
}

.btn:disabled,
.btn-primary:disabled,
.btn-primary-dark-background:disabled,
.btn-primary-inverse:disabled,
.btn-secondary:disabled,
.btn-secondary-brand:disabled,
.btn-secondary-cancel:disabled {
    border: 1px solid darkgrey;
    background-color: lightgrey;
    color: darkgrey;
    pointer-events: none;
    user-select: none;
}

/* Button Primary with primary brand box styling */
.btn-primary {
    background-color: white;
    border-color: var(--genesys-csdt-brand-navy);
    cursor: pointer;
    color: var(--genesys-csdt-brand-navy);
}

.btn-primary:hover {
    background-color: var(--genesys-csdt-brand-navy);
    color: white;
}

.btn-primary:focus,
.btn-primary:focus-within,
.btn-primary:focus-visible {
    border-color: var(--genesys-csdt-link-blue);
    outline: 1px solid var(--genesys-csdt-link-blue);
}

.btn-primary:active {
    border-color: var(--genesys-csdt-brand-navy);
}

/* Primary Button on dark blue background */
.btn-primary-dark-background {
    background-color: white;
    border: 1px solid white;
    cursor: pointer;
    color: var(--genesys-csdt-brand-navy);
}

/* Link styled like a Primary Button */
a.btn-primary-dark-background {
    color: var(--genesys-csdt-brand-navy);
    border-color: white;
    line-height: 1.5;
    height: 32px; /* do not delete. this equals 48px with padding to match buttons */
}

.btn-primary-dark-background:hover {
    background-color: var(--genesys-csdt-brand-light-blue);
    border-color: var(--genesys-csdt-brand-light-blue);
    color: white;
}

.btn-primary-dark-background:focus,
.btn-primary-dark-background:focus-within,
.btn-primary-dark-background:focus-visible {
    border-color: var(--genesys-csdt-link-blue);
    outline: 1px solid var(--genesys-csdt-link-blue);
    color: var(--genesys-csdt-brand-navy);
}

.btn-primary-dark-background:active {
    border-color: white;
    color: black;
}

/* Inverse Primary Button with inverse or flipped hover and default states */
.btn-primary-inverse {
    background-color: var(--genesys-csdt-brand-navy);
    border: 1px solid var(--genesys-csdt-brand-navy);
    cursor: pointer;
    color: white;
}

a.btn-primary-inverse {
    color: white;
    border-color: var(--genesys-csdt-brand-navy);
    line-height: 1.5;
    height: 32px; /* do not delete. this equals 48px with padding to match buttons */
}

.btn-primary-inverse:hover {
    background-color: white;
    color: var(--genesys-csdt-brand-navy);
}

.btn-primary-inverse:focus,
.btn-primary-inverse:focus-within,
.btn-primary-inverse:focus-visible {
    border-color: var(--genesys-csdt-link-blue);
    outline: 1px solid var(--genesys-csdt-link-blue);
}

.btn-primary-inverse:active {
    border-color: var(--genesys-csdt-brand-navy);
}

.btn-secondary {
    background-color: var(--genesys-csdt-dark-teal);
    border-color: var(--genesys-csdt-dark-teal);
    color: white;
}

a.btn-secondary {
    color: white;
    border-color: var(--genesys-csdt-dark-teal);
    line-height: 1.5;
    height: 32px; /* do not delete. this equals 48px with padding to match buttons */
}

.btn-secondary:hover {
    background-color: var(--genesys-csdt-brand-navy);
    border-color: var(--genesys-csdt-brand-navy);
    color: white;
}

.btn-secondary:focus,
.btn-secondary:focus-within,
.btn-secondary:focus-visible {
    border-color: var(--genesys-csdt-neutral-gray-2);
    outline: 1px solid var(--genesys-csdt-neutral-gray-2);
}

.btn-secondary:active {
    border-color: var(--genesys-csdt-dark-teal);
    background-color: var(--genesys-csdt-dark-teal);
}

.btn-secondary-brand {
    background-color: var(--genesys-csdt-brand-orange);
    border-color: var(--genesys-csdt-brand-orange);
    color: white;
}

a.btn-secondary-brand {
    color: white;
    border-color: var(--genesys-csdt-brand-orange);
    line-height: 1.5;
    height: 32px; /* do not delete. this equals 48px with padding to match buttons */
}

.btn-secondary-brand:hover {
    background-color: white;
    border-color: var(--genesys-csdt-brand-orange);
    color: var(--genesys-csdt-brand-orange);
}

.btn-secondary-brand:focus,
.btn-secondary-brand:focus-within,
.btn-secondary-brand:focus-visible {
    border-color: var(--genesys-csdt-brand-orange);
    outline: 1px solid var(--genesys-csdt-brand-orange-hover);
}

.btn-secondary-cancel {
    background-color: white;
    border-color: var(--genesys-csdt-dark-teal);
    color: var(--genesys-csdt-dark-teal);
}

a.btn-secondary-cancel {
    color: var(--genesys-csdt-dark-teal);
    border-color: var(--genesys-csdt-dark-teal);
    line-height: 1.5;
    /* TODO KNOW-1607 Should this be 32px like the other a.* classes with similar comments? */
    height: 34px; /* do not delete. this equals 48px with padding to match buttons */
}

.btn-secondary-cancel:hover {
    background-color: var(--genesys-csdt-dark-teal);
    border-color: var(--genesys-csdt-dark-teal);
    color: white;
}

.btn-secondary-cancel:focus,
.btn-secondary-cancel:focus-within,
.btn-secondary-cancel:focus-visible {
    border-color: var(--genesys-csdt-dark-teal);
    outline: 1px solid var(--genesys-csdt-dark-teal);
    color: var(--genesys-csdt-dark-teal);
}

.btn-secondary-cancel:active {
    border-color: var(--genesys-csdt-dark-teal);
    background-color: white;
}

a.btn-primary {
    line-height: 1.5;
    height: 32px; /* do not delete. this equals 48px with padding to match buttons */
}

button[animated] {
    padding: 0;
    border: none;
    background-color: white;
    background-color: transparent;
}

.button-link,
button.link-n,
button.primary-link,
button.cursor {
    text-align: left;
    border: none;
    padding: 0;
    font-family: Roboto, Helvetica, sans-serif;
}

button.primary-link {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: var(--genesys-csdt-brand-navy);
}

/* TODO KNOW-1607 Remove cursor property. Every usage of this class (except one) is also using the global .cursor class */
/* Also move to a file called borders.css or something */
.button-link {
    border: none;
    cursor: pointer;
}

/* TODO KNOW-1607 Replace usages of this class with .full-width and delete this class */
.button-image {
    width: 100%;
}

@media only screen and (max-width: 1020px) {
    /* Secondary Button styling */
    /* Total height = button height = 38px to match figma */
    .btn-secondary {
        background-color: white;
        border-color: var(--genesys-csdt-dark-teal);
        color: var(--genesys-csdt-dark-teal);
        font-size: 18px;
        line-height: 21px;
        height: 24px;
    }

    /* Mobile Link styled like a Secondary Button */
    /* Total height = font-size + line-height + height = 38px to match figma */
    a.btn-secondary {
        color: var(--genesys-csdt-dark-teal);
        border-color: var(--genesys-csdt-dark-teal);
        font-size: 18px;
        line-height: 21px;
        height: 24px;
    }

    /* Mobile Link style like a Secondary button with down arrow */
    /* Total height = button height = 38px to match figma */
    button.btn-secondary {
        height: 38px;
    }

    /* Mobile Secondary Button hover state */
    .btn-secondary:hover {
        background-color: var(--genesys-csdt-dark-teal);
        border-color: var(--genesys-csdt-dark-teal);
        color: white;
    }

    /* Mobile Secondary Button active state */
    .btn-secondary:active {
        border-color: var(--genesys-csdt-dark-teal);
        background-color: var(--genesys-csdt-dark-teal);
    }

    /* Mobile Secondary Brand button */
    /* Color states are the same as desktop only font, height, and line height chanegs */
    /* Total height = font-size + line-height + height = 38px to match Figma */
    a.btn-secondary-brand {
        font-size: 18px;
        line-height: 21px;
        height: 24px;
    }

    /* Mobile Button Primary button */
    /* Used for Sign In or Sign out */
    /* Total height = font-size + line-height + height = 38px to match Figma */
    input[type='button'].btn.btn-primary {
        font-size: 18px;
        line-height: 21px;
        height: 38px;
    }
}
